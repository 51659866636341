






















































































import Dialog from '@/calendesk/models/Dialog'
import dialogTypes from '@/components/dialogs/dialogTypes'
import dialogSize from '@/lib/calendesk-js-library/components/dialogs/dialogSize'
import Page from '@/calendesk/models/DTO/Response/Page'
import Vue from 'vue'
import draggable from 'vuedraggable'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import sharedConfirmDialogTypes from '@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes'
import { errorNotification, successNotification } from '@/lib/calendesk-js-library/tools/notification'
import { PageType } from '@/calendesk/models/BuilderTypes'
import CalendeskInformationMessage from '@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue'

export default Vue.extend({
  name: 'Pages',
  components: {
    CalendeskInformationMessage,
    draggable
  },
  data () {
    return {
      pageTypes: PageType,
      selectedPage: null,
      pages: Array<Page>(),
      menus: [
        { title: this.$trans('edit'), type: 'EDIT' },
        { title: this.$trans('remove'), type: 'REMOVE' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      draftPages: 'builder/getDraftPages'
    })
  },
  watch: {
    draftPages () {
      this.clonePages()
    }
  },
  mounted () {
    this.clonePages()
  },
  methods: {
    ...mapMutations({
      setCurrentPage: 'builder/SET_CURRENT_PAGE'
    }),
    ...mapActions({
      updatePage: 'builder/updatePage',
      removePage: 'builder/removePage',
      openDialog: 'dialog/open',
      openConfirmDialog: 'confirmDialog/open',
      closeConfirmDialog: 'confirmDialog/close',
      setCommonDialogLoader: 'loader/setCommonDialogLoader',
      closeSidebar: 'sidebar/closeSidebar',
      closeSidebarDetails: 'sidebar/closeSidebarDetails'
    }),
    changePage (page: Page) {
      if (page.type === this.pageTypes.MAIN_PAGE ||
        page.type === this.pageTypes.PAGE) {
        this.setCurrentPage(page)
        this.closeSidebar()
        this.closeSidebarDetails()
      } else if (page.type === this.pageTypes.REDIRECT || page.type === this.pageTypes.BLOG_HANDY) {
        this.menuActionHandle('EDIT', page)
      }
    },
    iconForPage (page: Page): string {
      if (page.type === this.pageTypes.MAIN_PAGE) {
        return '$home'
      } else if (page.authOnly) {
        return '$password'
      }

      return '$file-2'
    },
    clonePages () {
      if (this.draftPages && Array.isArray(this.draftPages)) {
        const pages: Page[] = [...this.draftPages]
        this.pages = pages.sort((a: Page, b: Page) => {
          // Ensure the main page comes first
          if (a.type === PageType.MAIN_PAGE) return -1
          if (b.type === PageType.MAIN_PAGE) return 1

          // Then sort by configuration.wb_position numerically, with a fallback for missing values
          const positionA = a.configuration?.wb_position ?? Number.MAX_VALUE
          const positionB = b.configuration?.wb_position ?? Number.MAX_VALUE

          return positionA - positionB
        })
      }
    },
    dragAndDropAction (event: any) {
      if (event.moved) {
        const page: Page = event.moved.element
        const currentItemIndex = event.moved.newIndex
        let previous: number = this.pages[currentItemIndex - 1]?.configuration.wb_position
        let next: number = this.pages[currentItemIndex + 1]?.configuration.wb_position

        if (!previous && !next) {
          previous = 65535
          next = 65535 * 2
        } else if (!previous) {
          previous = +next / 2
        } else if (!next) {
          next = +previous * 2
        }

        if (previous && next) {
          page.configuration.wb_position = (+previous + +next) / 2
        } else if (next) {
          page.configuration.wb_position = +next / 2
        } else {
          page.configuration.wb_position = (+previous) + (+previous)
        }

        this.pages[currentItemIndex].configuration.wb_position = page.configuration.wb_position
        this.updatePage(page)
      }
    },
    openDialogHandle () {
      this.openDialog(new Dialog(true, dialogTypes.BUILDER_ADD_PAGE, this.$trans('wb_add_new_page'), dialogSize.LARGE))
    },
    menuActionHandle (type: string, page: Page): void {
      if (type === 'EDIT') {
        this.openDialog(new Dialog(true, dialogTypes.BUILDER_ADD_PAGE, this.$trans('wb_edit_page'), dialogSize.LARGE, false, 'center', page))
      } else if (type === 'REMOVE') {
        this.openConfirmDialog({
          type: sharedConfirmDialogTypes.COMMON,
          title: this.$trans('wb_remove_page_confirm_dialog_title'),
          warning: this.$trans('wb_remove_page_confirm_dialog_text'),
          confirmAction: () => {
            this.setCommonDialogLoader(true)
            this.removePage(page).then(() => {
              successNotification('wb_remove_page_success_text')
            }).catch((error) => {
              errorNotification('error_occurred', error)
            }).finally(() => {
              this.setCommonDialogLoader(false)
              this.closeConfirmDialog()
            })
          }
        })
      }
    }
  }
})
